import Proxy from '@/modules/shared/proxies/proxy'

/**
 * Class UserProxy
 */
class UserProxy extends Proxy {
  /**
   * Constructor.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/users', parameters)
  }

  /**
   * @returns {Promise}
   */
  current () {
    return this.submit('get', `/${this.endpoint}/current`)
  }

  /**
   * @returns {Promise}
   */
  updateCurrent () {
    return this.submit('put', `/${this.endpoint}/`)
  }

  /**
   * @param {Object} user
   * @returns {Promise}
   */
  save (user, options) {
    return user.id
      ? this.submit('put', `/${this.endpoint}/${user.id}`, user, options)
      : this.submit('post', `/${this.endpoint}`, user, options)
  }

  /**
   *
   * @param userId
   * @param value
   * @param options
   * @returns {Promise}
   */
  enable (userId, value, options) {
    return this.submit('put', `/${this.endpoint}/${userId}/enable`, value, options)
  }

  /**
   * @param {Object} user
   * @returns {Promise}
   */
  delete (userId, options) {
    return this.submit('delete', `/${this.endpoint}/${userId}`, null, options)
  }

  /**
   * @returns {Promise}
   */
  getAdvisors () {
    return this.submit('get', `/${this.endpoint}/advisors`)
  }
}

const instance = new UserProxy()
export {
  instance as default,
  UserProxy
}
