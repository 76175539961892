import { IntegrationProxy } from '@/modules/shared/integration/integration-proxy'
import * as types from './mutation-types'

export const getUserOrganizations = ({ commit }) => new Promise((resolve, reject) => {
  new IntegrationProxy().getUserOrganizations().then((organizations) => {
    commit(types.FETCH_USER_ORGANIZATION, organizations)
    resolve(organizations)
  })
    .catch(e => reject(e))
})

export const getIdentities = ({ commit }, payload) => new Promise((resolve, reject) => {
  new IntegrationProxy().getIdentities(payload).then((res) => {
    commit(types.FETCH_USER_ORGANIZATION, res)
    resolve(res)
  })
    .catch(e => reject(e))
})

export const getUserContexts = ({ commit }, payload) => new Promise((resolve, reject) => {
  new IntegrationProxy().getUserContexts(payload).then((res) => {
    commit(types.GET_USER_CONTEXTS, res)
    resolve(res)
  })
    .catch(e => reject(e))
})

export const setUserContext = ({ commit }, payload) => new Promise((resolve, reject) => {
  new IntegrationProxy().setUserContext(payload.id, payload.userContext).then((res) => {
    resolve(res)
  })
    .catch(e => reject(e))
})

export default {
  getUserOrganizations,
  getIdentities,
  getUserContexts,
  setUserContext
}
