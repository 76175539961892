export const FETCH_USER_ORGANIZATION = 'FETCH_USER_ORGANIZATION'
export const GET_IDENTITIES = 'GET_IDENTITIES'
export const GET_USER_CONTEXTS = 'GET_USER_CONTEXTS'
export const SET_USER_CONTEXTS = 'SET_USER_CONTEXTS'
export default {
  FETCH_USER_ORGANIZATION,
  GET_IDENTITIES,
  GET_USER_CONTEXTS,
  SET_USER_CONTEXTS
}
