/**
 * The store of the application.
 */
import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import client from '@/modules/shared/client/store/'
import user from '@/modules/shared/user/store/'
import integration from '@/modules/shared/integration/store/'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
  modules: {
    client,
    user,
    integration
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
