/* ============
 * State of the auth module
 * ============
 *
 * The initial state of the auth module.
 */

export default {
  updatedUser: {},
  currentUser: {},
  advisors: []
}
