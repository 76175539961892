import {
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  GET_CURRENT_USER,
  UPDATE_CURRENT_USER,
  ENABLE_DISABLE_USER,
  GET_ADVISORS
} from './mutation-types'

export default {
  [CREATE_USER] (state, payload) {
    state.updatedUser = payload
  },
  [UPDATE_USER] (state, payload) {
    state.updatedUser = payload
  },
  [ENABLE_DISABLE_USER] (state, payload) {
    state.updatedUser = payload
  },
  [GET_CURRENT_USER] (state, payload) {
    state.currentUser = payload
  },
  [UPDATE_CURRENT_USER] (state, payload) {
    state.currentUser = payload
  },
  [GET_ADVISORS] (state, payload) {
    state.advisors = payload
  },
  [DELETE_USER] (state, payload) {
    state.deleteUserResult = payload
  }
}
