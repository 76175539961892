import Vue from 'vue'
import { UserProxy } from '@/modules/shared/user/user-proxy'
import * as types from './mutation-types'

export const saveUser = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    success: Vue.i18n.t('user.updateUserSuccess'),
    error: Vue.i18n.t('user.updateUserError')
  }
  const type = payload.id ? types.UPDATE_USER : types.CREATE_USER
  new UserProxy().save(payload, { toast })
    .then((newUser) => {
      commit(type, newUser)
      resolve(newUser)
    })
    .catch(e => reject(e))
})

export const getCurrentUser = ({ commit }) => new Promise((resolve, reject) => {
  new UserProxy().current().then((currentUser) => {
    commit(types.GET_CURRENT_USER, currentUser)
    resolve(currentUser)
  })
    .catch(e => reject(e))
})

export const updateCurrentUser = ({ commit }, payload) => new Promise((resolve, reject) => {
  new UserProxy(payload)
    .updateCurrent()
    .then((currentUser) => {
      commit(types.UPDATE_CURRENT_USER, currentUser)
      resolve(currentUser)
    })
    .catch(e => reject(e))
})

export const deleteUser = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    success: Vue.i18n.t('user.deleteUserSuccess'),
    error: Vue.i18n.t('user.deleteErrorMsg')
  }

  new UserProxy().delete(payload, { toast })
    .then(() => {
      commit(types.DELETE_USER, 'success')
      resolve()
    })
    .catch(e => reject(e))
})

export const enableDisableUser = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    success: Vue.i18n.t('user.updateUserSuccess'),
    error: Vue.i18n.t('user.updateUserError')
  }
  new UserProxy(payload)
    .enable(payload.id, payload.enabled, { toast })
    .then((currentUser) => {
      commit(types.ENABLE_DISABLE_USER, currentUser)
      resolve(currentUser)
    })
    .catch(e => reject(e))
})

export const getAdvisors = ({ commit }) => new Promise((resolve, reject) => {
  new UserProxy().getAdvisors().then((advisors) => {
    commit(types.GET_ADVISORS, advisors)
    resolve(advisors)
  })
    .catch(e => reject(e))
})

export default {
  saveUser,
  deleteUser,
  getCurrentUser,
  updateCurrentUser,
  enableDisableUser,
  getAdvisors
}
