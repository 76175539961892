import {
  FETCH_USER_ORGANIZATION,
  GET_IDENTITIES,
  GET_USER_CONTEXTS
} from './mutation-types'

export default {
  [FETCH_USER_ORGANIZATION] (state, payload) {
    state.organizations = payload
  },
  [GET_IDENTITIES] (state, payload) {
    state.identities = payload
  },
  [GET_USER_CONTEXTS] (state, payload) {
    state.userContexts = payload
  }
}
