export const REGISTER_RESULT = {
  FAILED: '1',
  CONSIDERED: '2',
  PASSED: '3'
}

export const CREDIT_SCORE_STATUS = {
  FAILED: 'FAILED',
  OK: 'OK',
  REMARKS: 'REMARKS'
}

export const CLIENT_STATUS = {
  PROSPECT: 'PROSPECT',
  IN_PRODUCTION: 'IN_PRODUCTION',
  CANCELLED: 'CANCELLED',
  ONBOARDING: 'ONBOARDING',
  REPORTING: 'REPORTING'
}

export const INTEGRATION_TYPE = {
  COMMERCIAL_DELPHI: 'COMMERCIAL_DELPHI',
  DASHBOARD_INFO: 'DASHBOARD_INFO'
//  EXPERIAN: 'EXPERIAN', // Commented out. Only used for internal integrations. Manually set.
}

export default {
  REGISTER_RESULT,
  CLIENT_STATUS,
  CREDIT_SCORE_STATUS,
  INTEGRATION_TYPE
}
