import messages from 'vee-validate/dist/locale/nb_NO'
import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'

// Merge the locales.
Validator.localize('no', messages)

Vue.use(VeeValidate, {
  locale: 'no',
  fieldsBagName: 'formFields'
})
