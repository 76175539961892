<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12 mb-2">
        <div id="breadcrumb-section">
          <span>Du er her: </span>
          <nav>
            <ul>
              <li>
                <a title="Finansiering" href="https://paretobank.no/finansiering">Finansiering</a>
              </li>
              <li>
                <a title="Selskapsfinansiering" href="https://paretobank.no/finansiering/selskapsfinansiering">Selskapsfinansiering</a>
              </li>
              <li>
                <a v-if="isMypageLogin" href="/">{{t('landing.flexibleCredit')}}</a>
                <router-link v-if="!isMypageLogin"  :to="{ path: '/'}"> {{t('landing.flexibleCredit')}} </router-link>
              </li>
              <li v-if="isMypageLogin">
                <router-link to="index">  {{t('landing.loginToMyPage')}} </router-link>
              </li>
              <li v-if="$route.name == 'signup'">
                <router-link to="signup" >  {{t('landing.signUp')}} </router-link>
              </li>
              <li v-if="$route.name == 'howitworks'">
                <router-link to="howitworks" >  {{t('landing.howItWorks')}} </router-link>
              </li>
              <li v-if="$route.name == 'criteria'">
                <router-link to="criteria" >  {{t('landing.criteria')}} </router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TranslateMixin from '../mixins/translate'

export default {
  name: 'BreadcrumbFlexibleCredit',
  mixins: [TranslateMixin],
  props: {
    isMypageLogin: {
      type: Boolean,
      default: false
    }
  }
}
</script>
