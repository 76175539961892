import { ClientProxy } from '@/modules/shared/client/client-proxy'
import { downloadFile } from '@/utils/download'
import * as types from './mutation-types'

const LEDGER_GROUP_CACHED = {}

const createDefaultMonthlyGroupDetailItem = () => ({
  group: '',
  name: '',
  JANUARY: 0,
  FEBRUARY: 0,
  MARCH: 0,
  APRIL: 0,
  MAY: 0,
  JUNE: 0,
  JULY: 0,
  AUGUST: 0,
  SEPTEMBER: 0,
  OCTOBER: 0,
  NOVEMBER: 0,
  DECEMBER: 0,
  TOTAL: 0
})

const last4YearsTotal = () => {
  const currentYear = new Date().getFullYear()
  return {
    [currentYear]: 0,
    [currentYear - 1]: 0,
    [currentYear - 2]: 0,
    [currentYear - 3]: 0
  }
}

export const monthlyResultInLast4Years = () => {
  const currentYear = new Date().getFullYear()
  return {
    [currentYear.toString()]: [],
    [(currentYear - 1).toString()]: [],
    [(currentYear - 2).toString()]: [],
    [(currentYear - 3).toString()]: []
  }
}

function refinedMonthlyGroupLedgerData (groups) {
  const result = monthlyResultInLast4Years()

  Object.keys(groups.data).forEach((groupKey) => {
    const groupItem = groups.data[groupKey]

    Object.keys(groupItem.amounts).forEach((year) => {
      const yearData = groupItem.amounts[year]
      if (yearData) {
        const refinedLegerData = createDefaultMonthlyGroupDetailItem()
        refinedLegerData.name = groupKey
        refinedLegerData.expandible = groupItem.expandible
        refinedLegerData.numberOfEntries = groupItem.numberOfEntries

        Object.keys(yearData).forEach((month) => {
          refinedLegerData[month] = yearData[month]
        })

        if (result[year.toString()] !== undefined) {
          result[year.toString()] = [
            ...result[year.toString()],
            refinedLegerData
          ]
        }
      }
    })
  })
  return result
}

/*
Result format
{
  '2016': [
    {
      group: 'GroupName',
      name: '20111 - ledger name',
      JAN: 10000
      ...
      DEC: 1000,
      TOTAL: 200000
    }
  ],
  '2017': [
    {
      group: 'GroupName',
      name: '20111 - ledger name',
      JAN: 10000
      ...
      DEC: 1000,
      TOTAL: 200000
    }
  ],
  '2018': [
    {
      group: 'GroupName',
      name: '20111 - ledger name',
      JAN: 10000
      ...
      DEC: 1000,
      TOTAL: 200000
    }
  ],
}
* */
function refinedMonthlyLedgerDetails (group, groupName) {
  /**
   *  getYearlyLedgerDetails
   * @param ledgerDetails
   * @param year
   * @returns { group: 'GroupName', name: '2-name', JAN: 10000 , DEC: 1000, TOTAL: 200000 }
   */
  function getYearlyLedgerDetails (ledgerDetails, year) {
    return ledgerDetails.filter(ledgerItem => ledgerItem.amounts[year])
      .map((ledgerItem) => {
        const resultItem = createDefaultMonthlyGroupDetailItem()
        resultItem.group = groupName
        resultItem.name = `${ledgerItem.accountNumber} - ${ledgerItem.accountName || ''}`
        const yearAmounts = ledgerItem.amounts[year]
        if (yearAmounts) {
          Object.keys(yearAmounts).forEach((month) => {
            resultItem[month] = yearAmounts[month]
          })
        }
        return resultItem
      })
  }

  const result = monthlyResultInLast4Years()
  Object.keys(result).forEach((year) => {
    const ledgerDetails = group.data
    result[year.toString()] = getYearlyLedgerDetails(ledgerDetails, year)
  })
  return result
}

function refinedYearlyLedgerDetails (group, groupName) {
  const ledgerDetails = group.data
  return ledgerDetails.map((ledgerItem) => {
    const resultItem = {
      group: groupName,
      name: `${ledgerItem.accountNumber} - ${ledgerItem.accountName || ''}`
    }
    Object.keys(ledgerItem.amounts).forEach((year) => {
      const yearAmounts = ledgerItem.amounts[year]
      resultItem[year] = yearAmounts.TOTAL
    })
    return resultItem
  })
}

function refinedYearlyGroupLedgerData (groups) {
  let result = []
  Object.keys(groups.data).forEach((groupKey) => {
    const groupItem = groups.data[groupKey]

    const refinedLegerData = {
      ...last4YearsTotal(),
      name: groupKey,
      expandible: groupItem.expandible,
      numberOfEntries: groupItem.numberOfEntries
    }
    Object.keys(groupItem.amounts).forEach((year) => {
      refinedLegerData[year] = groupItem.amounts[year].TOTAL
    })

    result = [
      ...result,
      refinedLegerData
    ]
  })
  return result
}

export const getLedgerDetails = ({ commit }, payload) => new Promise((resolve, reject) => {
  const resolveLedgerDetails = (response) => {
    commit(types.GET_CLIENT_YEARLY_LEDGER_DETAILS, {
      group: payload.group,
      data: refinedYearlyLedgerDetails(response, payload.group)
    })

    commit(types.GET_CLIENT_MONTHLY_LEDGER_DETAILS, {
      group: payload.group,
      data: refinedMonthlyLedgerDetails(response, payload.group)
    })
    resolve(response)
  }
  if (LEDGER_GROUP_CACHED[payload.id] && LEDGER_GROUP_CACHED[payload.id][payload.group]) {
    resolveLedgerDetails(LEDGER_GROUP_CACHED[payload.id][payload.group])
  } else {
    new ClientProxy({ ledgerGroup: payload.group }).getLedgerDetails(payload.id)
      .then((response) => {
        if (!LEDGER_GROUP_CACHED[payload.id]) {
          LEDGER_GROUP_CACHED[payload.id] = {}
        }

        if (!LEDGER_GROUP_CACHED[payload.id][payload.group]) {
          LEDGER_GROUP_CACHED[payload.id][payload.group] = response
        }
        resolveLedgerDetails(response)
      })
      .catch(e => reject(e))
  }
})

export const getSummaryLedger = ({ commit }, payload) => new Promise((resolve, reject) => {
  new ClientProxy().getSummaryLedger(payload.id)
    .then((response) => {
      const yearlyLedgerData = {
        balanceStatement: refinedYearlyGroupLedgerData(response.balanceStatement),
        incomeStatement: refinedYearlyGroupLedgerData(response.incomeStatement)
      }

      const monthlyLedgerData = {
        balanceStatement: refinedMonthlyGroupLedgerData(response.balanceStatement),
        incomeStatement: refinedMonthlyGroupLedgerData(response.incomeStatement)
      }
      commit(types.GET_CLIENT_LEDGER, response)
      commit(types.GET_CLIENT_YEARLY_LEDGER, yearlyLedgerData)
      commit(types.GET_CLIENT_MONTHLY_LEDGER, monthlyLedgerData)
      resolve(response)
    })
    .catch(e => reject(e))
})

export const updateLedger = ({ commit, dispatch }, payload) => new Promise((resolve, reject) => {
  const options = { showLoading: true, toast: { success: 'Update success!' } }
  new ClientProxy().updateLedger(payload, options)
    .then((result) => {
      commit(types.UPDATE_LEDGER, result)
      resolve(result)
      dispatch('fetchClient', payload).then((client) => {
        dispatch('getSummaryLedger', { id: client.id })
      })
    })
    .catch(e => reject(e))
})

export const exportLedgerToExcel = ({ commit, dispatch }, payload) =>
  new Promise((resolve, reject) => {
    new ClientProxy().exportLedger(payload)
      .then((result) => {
        const content = result.data
        const fileName = result.headers['content-disposition'].match(/filename=(.+)$/)[1]
        downloadFile(fileName, content)
        resolve(content)
      })
      .catch(e => reject(e))
  })

export default {
  getLedgerDetails,
  getSummaryLedger,
  updateLedger,
  monthlyResultInLast4Years,
  exportLedgerToExcel
}
