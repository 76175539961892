import Proxy from '@/modules/shared/proxies/proxy'
import Vue from 'vue'
/**
 * Class ClientProxy
 */
class ClientProxy extends Proxy {
  /**
   * Constructor.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/clients', parameters)
  }

  /**
   *
   * @param id
   * @param options
   * @returns {Promise}
   */
  calculateCreditScore (id, options = { showLoading: true }) {
    return this.submit('post', `/${this.endpoint}/${id}/creditscore/update`, null, options)
  }

  /**
   * Create client & user direclty without onboarding processs
   * @param orgNumber
   * @param user
   * @param options
   * @returns {Promise}
   */
  createClientUser (orgNumber, user, options = { showLoading: true }) {
    return this.submit('post', `/${this.endpoint}/supervised?orgNumber=${orgNumber}`, user, options)
  }

  /**
   * transitionClient
   * @param id
   * @param status
   * @param subStatus
   * @param options
   * @returns {Promise}
   */
  transitionClient (id, status, subStatus = 'NONE', options = { showLoading: true }) {
    return this.submit('put', `/${this.endpoint}/${id}/transition?clientStatus=${status}&clientSubStatus=${subStatus}`, null, options)
  }

  /**
   * @param {string} id
   * @param {number} amount
   * @returns {Promise}
   */
  repay (id, amount) {
    const toast = {
      success: Vue.i18n.t('client.onRepaySuccess')
    }
    return this.submit('put', `/${this.endpoint}/${id}/credit/repay`, amount, { toast })
  }

  /**
   * @param {string} id
   * @param {number} amount
   * @returns {Promise}
   */
  withdraw (id, amount) {
    const toast = {
      success: Vue.i18n.t('client.onWithdrawSuccess')
    }
    return this.submit('put', `/${this.endpoint}/${id}/credit/withdraw`, amount, { toast })
  }

  /**
   * @param item
   * @param options
   * @returns {Promise}
   */
  create (item, options = { showLoading: true }) {
    return super.create(item, options)
  }

  /**
   * mass creates clients
   * @param orgNumbers
   * @param clientStatus
   * @param options
   * @returns {Promise}
   */
  massCreateClient (orgNumbers, clientStatus, options = {}) {
    return this.submit('post', `/${this.endpoint}/masscreate?clientStatus=${clientStatus}`, orgNumbers, options)
  }

  /**
   *
   * @param id
   * @param credentials
   * @param options
   * @returns {Promise}
   */
  setCredentials (id, credentials, options = {}) {
    return this.submit('put', `/${this.endpoint}/${id}/credentials`, credentials, options)
  }

  /**
   * @param id
   * @param accountingSystem
   * @param options
   * @returns {Promise}
   */
  setAccountingSystem (id, accountingSystem, options = {}) {
    return this.submit('put', `/${this.endpoint}/${id}/accountingsystem`, accountingSystem, options)
  }

  /**
   *
   * @param id
   * @param key
   * @param options
   * @returns {Promise}
   */
  setAccountingSystemKey (id, key, options = {}) {
    return this.submit('put', `/${this.endpoint}/${id}/accountingsystem/key?key=${key}`, null, options)
  }

  /**
   * @param id
   * @returns {Promise}
   */
  getAccountingSystem (id) {
    return this.submit('get', `/${this.endpoint}/${id}/accountingsystem`)
  }

  /**
   * @param id
   * @param value
   * @param options
   * @returns {Promise}
   */
  setCreditAccount (id, value, options = {}) {
    return this.submit('put', `/${this.endpoint}/${id}/creditaccount`, value, options)
  }

  /**
   * @param id
   * @param value
   * @param options
   * @returns {Promise}
   */
  setOperatingAccount (id, value, options = {}) {
    return this.submit('put', `/${this.endpoint}/${id}/operatingaccount`, value, options)
  }

  /**
   *
   * @param id
   * @param value
   * @param options
   * @returns {Promise}
   */
  setOnWatchList (id, value, options = {}) {
    return this.submit('put', `/${this.endpoint}/${id}/watchlist`, value, options)
  }

  /**
   * @param id
   * @param options
   * @returns {Promise}
   */
  updateInvoices (id, options = {}) {
    return this.submit('post', `/${this.endpoint}/${id}/invoices/update`, id, options)
  }

  /**
   * @param id
   * @param options
   * @returns {Promise}
   */
  refetchInvoices (id, options = {}) {
    return this.submit('post', `/${this.endpoint}/${id}/invoices/refetch`, id, options)
  }

  /**
   *
   * @param id
   * @param options
   * @returns {Promise}
   */
  produceDocument (id, options = {}) {
    return this.submit('post', `/${this.endpoint}/${id}/documents`, id, options)
  }

  /**
   *
   * @param id
   * @param value
   * @param options
   * @returns {Promise}
   */
  addEndUser (id, value, options = {}) {
    return this.submit('post', `/${this.endpoint}/${id}/users`, value, options)
  }

  /**
   *
   * @param id
   * @returns {Promise}
   */
  getPaymentAuthorization (id) {
    return this.submit('get', `/${this.endpoint}/${id}/paymentauthorization`)
  }

  /**
   * updateSettings
   * @param id
   * @param value
   * @param options
   * @returns {Promise}
   */
  updateSettings (id, value, options = {}) {
    return this.submit('put', `/${this.endpoint}/${id}/settings`, value, options)
  }

  /**
   * deleteTransaction
   * @param id
   * @param transactionId
   * @param options
   * @returns {Promise}
   */
  deleteTransaction (id, transactionId, options = {}) {
    return this.submit('put', `/${this.endpoint}/${id}/transactions/${transactionId}/cancel`, null, options)
  }

  /**
   * updateAdvisor
   * @param id
   * @param userId
   * @param options
   * @returns {Promise}
   */
  updateAdvisor (id, userId, options = {}) {
    const userIdParam = !userId ? '' : `?userId=${userId}`
    return this.submit('put', `/${this.endpoint}/${id}/advisor${userIdParam}`, null, options)
  }

  /**
   * Remove user associate with client
   * @param clientId
   * @param userId
   * @param options
   * @returns {Promise}
   */
  removeClientUser (clientId, userId, options = {}) {
    return this.submit('post', `/${this.endpoint}/${clientId}/users/${userId}/remove`, null, options)
  }

  /**
   * getClientGroupedLedger
   * @param id
   * @param options
   * @returns {Promise}
   */
  getClientGroupedLedger (id, options = {}) {
    return this.submit('get', `/${this.endpoint}/${id}/ledger/grouped`, null, options)
  }

  /**
   * getLedgerDetails
   * @param id
   * @param group
   * @param options
   */
  getLedgerDetails (id, options = {}) {
    return this.submit('get', `/${this.endpoint}/${id}/ledger/details`, null, options)
  }

  /**
   * getSummaryLedger
   * @param id
   * @param options
   * @returns {Promise}
   */
  getSummaryLedger (id, options = {}) {
    return this.submit('get', `/${this.endpoint}/${id}/ledger/summary`, null, options)
  }

  /**
   * updateLedger
   * @param id
   * @param options
   * @returns {Promise}
   */
  updateLedger (id, options = { showLoading: true }) {
    return this.submit('put', `/${this.endpoint}/${id}/ledger`, null, options)
  }

  /**
   * exportLedger
   * @param id
   * @param options
   * @returns {Promise}
   */
  exportLedger (id, options = { showResponseHeaders: true }) {
    return this.submit('get', `/${this.endpoint}/${id}/ledger/export`, null, options)
  }

  /**
   * add tags
   * @param id
   * @param options
   * @returns {Promise}
   */
  addTag (id, tagName, options = {}) {
    return this.submit('put', `/${this.endpoint}/${id}/tags/add`, tagName, options)
  }

  /**
   * add tags
   */
  removeTag (id, tagId, options = {}) {
    return this.submit('put', `/${this.endpoint}/${id}/tags/${tagId}/remove`, null, options)
  }

  /**
   * get client tags
   */
  getTags (id, options = {}) {
    return this.submit('get', `/${this.endpoint}/${id}/tags`, null, options)
  }

  /**
   * Get possible sub status of the client
   */
  getClientSubStatuses (id, status, options = {}) {
    return this.submit('get', `/${this.endpoint}/${id}/substatuses?clientStatus=${status}`, null, options)
  }

  /**
   * Get possible transition status of client base on current status
   */
  getLegalClientTransitions (id, options = {}) {
    return this.submit('get', `/${this.endpoint}/${id}/transitions`, null, options)
  }

  /**
   * Delete client
   */
  deleteClient (id, options = {}) {
    return this.submit('delete', `/${this.endpoint}/${id}`, null, options)
  }

  /**
   * Update follow up date
   */
  updateFollowUpDate (clientId, date, options = {}) {
    return this.submit('post', `/${this.endpoint}/${clientId}/followup`, date, options)
  }

  /**
   * saveOverDraft
   * @param clientId
   * @param payload
   * @param options
   * @returns {Promise}
   */
  saveOverDraft (clientId, payload, options = {}) {
    return this.submit(payload.id ? 'put' : 'post', `/${this.endpoint}/${clientId}/overdraft`, payload, options)
  }

  /**
   * deleteOverDraft
   * @param clientId
   * @param overDraftId
   * @param options
   * @returns {Promise}
   */
  deleteOverDraft (clientId, overDraftId, options = {}) {
    return this.submit('delete', `/${this.endpoint}/${clientId}/overdraft/${overDraftId}`, null, options)
  }
}

const instance = new ClientProxy()
export {
  instance as default,
  ClientProxy
}
