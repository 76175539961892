/**
 * For a multi-language application, you can
 * specify the languages you want to use here.
 */

import BackOfficeLocale from '@/modules/backoffice/locales'
import MyPageLocale from '@/modules/mypage/locales'
import OnboardingLocale from '@/modules/onboarding/locales'
import ClientLocale from '@/modules/shared/client/locales'
import UserLocale from '@/modules/shared/user/locales'
import EventLocale from '@/modules/shared/events/locales'
import en from './en'
import no from './no'

export default {
  en: {
    common: en,
    client: ClientLocale.en,
    user: UserLocale.en,
    event: EventLocale.no,
    backoffice: BackOfficeLocale.en,
    mypage: MyPageLocale.en,
    onboarding: OnboardingLocale.en
  },
  no: {
    common: no,
    client: ClientLocale.no,
    user: UserLocale.no,
    event: EventLocale.no,
    backoffice: BackOfficeLocale.no,
    mypage: MyPageLocale.no,
    onboarding: OnboardingLocale.no
  }
}
