import Proxy from '@/modules/shared/proxies/proxy'

/**
 * Class StatisticProxy
 */
class StatisticProxy extends Proxy {
  /**
   * Constructor.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/stats/client', parameters)
  }

  /**
   * getCreditAmounts
   */
  getCreditAmounts (id, payload, options = {}) {
    return this.submit('get', `/${this.endpoint}/${id}/credit`, payload, options)
  }

  /**
   * getCreditAmountDetails
   */
  getCreditAmountDetails (id, options = {}) {
    return this.submit('get', `/${this.endpoint}/${id}/credit/details`, null, options)
  }

  /**
   * getGlobalCreditAmounts
   */
  getGlobalCreditAmounts (payload, options = {}) {
    return this.submit('get', `/${this.endpoint}/credit`, payload, options)
  }
}

export default StatisticProxy
