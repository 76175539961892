import Vue from 'vue'
import { ClientProxy } from '@/modules/shared/client/client-proxy'
import ToastUtils from '@/utils/toast'
import NumericUtils from '@/utils/numeric'
import * as types from './mutation-types'

const onSuccess = (commit, resolve, actionType) => (result) => {
  commit(actionType, result)
  resolve(result)
}

export const repay = ({ commit }, payload) => new Promise((resolve, reject) => {
  new ClientProxy().repay(payload.client.id, payload.amount)
    .then(onSuccess(commit, resolve, types.REPAY))
    .catch(e => reject(e))
})

export const withdraw = ({ commit }, payload) => new Promise((resolve, reject) => {
  const widthdraw = payload.amount + payload.client.drawnAmount + payload.client.pendingAmount
  if (widthdraw > payload.client.creditLimit) {
    ToastUtils.showToast('error', Vue.i18n.t('client.onWithdrawError').replace('%s1', NumericUtils.formatNumber(widthdraw)).replace('%s2', NumericUtils.formatNumber(payload.client.creditLimit)))
    reject(true)
    return
  }
  new ClientProxy().withdraw(payload.client.id, payload.amount)
    .then(onSuccess(commit, resolve, types.WITHDRAW))
    .catch(e => reject(e))
})

export const calculateCreditScore = ({ commit }, payload) => new Promise((resolve, reject) => {
  const options = {
    showLoading: true,
    toast: {
      subject: 'Credit Score'
    }
  }
  new ClientProxy().calculateCreditScore(payload, options)
    .then(onSuccess(commit, resolve, types.CALCULATE_CREDIT_SCORE))
    .catch(e => reject(e))
})

export const updateInvoices = ({ commit }, payload) => new Promise((resolve, reject) => {
  const options = { showLoading: true, toast: { success: 'Update success!' } }
  new ClientProxy().updateInvoices(payload, options)
    .then(onSuccess(commit, resolve, types.UPDATE_INVOICES))
    .catch(e => reject(e))
})

export const refetchInvoices = ({ commit }, payload) => new Promise((resolve, reject) => {
  const options = { showLoading: true, toast: { success: 'Update success!' } }
  new ClientProxy().refetchInvoices(payload, options)
    .then(onSuccess(commit, resolve, types.UPDATE_INVOICES))
    .catch(e => reject(e))
})

export default {
  calculateCreditScore,
  updateInvoices,
  refetchInvoices,
  repay,
  withdraw
}
