import ActuatorProxy from '../modules/shared/proxies/actuator-proxy'

const GTM_KEY = 'GTM-P6B2PFJ'
const GTM_TARGET_ENV = 'prod'

const registerGTM = (currentEnv, key) => {
  if (currentEnv !== GTM_TARGET_ENV) {
    return
  }
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
  const firstScript = document.getElementsByTagName('script')[0]
  const scriptElement = document.createElement('script')
  scriptElement.async = true
  scriptElement.src = `https://www.googletagmanager.com/gtm.js?id=${key}`
  firstScript.parentNode.insertBefore(scriptElement, firstScript)
}

const init = () => new Promise((resolve) => {
  let currentEnv = localStorage.getItem('env')
  if (!currentEnv) {
    new ActuatorProxy().getInfo().then((res) => {
      currentEnv = res.profiles[0]
      localStorage.setItem('env', currentEnv)
      registerGTM(currentEnv, GTM_KEY)
      resolve(true)
    })
  } else {
    registerGTM(currentEnv, GTM_KEY)
    resolve(true)
  }
})

export default {
  init
}
