/*
 * Norwegian language file.
 */
import * as landing from './landing.json'
import * as hiw from './hiw.json'
import * as register from './register.json'
import * as accountingSystem from './accounting-system.json'
import * as criteria from './criteria.json'
import * as contact from './contact.json'
import * as featured from './featured.json'
import * as signup from './signup.json'

export default {
  landing,
  hiw,
  register,
  accountingSystem,
  criteria,
  contact,
  featured,
  signup
}
