/* ============
 * Translate Mixin
 * ============
 */

export default {
  methods: {
    t (text) {
      return this.$t(`onboarding.${text}`)
    },
    changeLanguage () {
      this.$i18n.locale = this.$i18n.locale === 'no' ? 'en' : 'no'
      localStorage.setItem('lang', this.$i18n.locale)
    }
  }
}
