/* ============
 * Main File
 * ============
 *
 * Will initialize the application.
 */

import Vue from 'vue'

/* ============
 * Plugins
 * ============
 *
 * Import and bootstrap the plugins.
 */
import 'promise-polyfill/src/polyfill'
import '@/plugins/vuex'
import '@/plugins/config'
import '@/plugins/axios'
import { i18n } from '@/plugins/vue-i18n'
import '@/plugins/bootstrap'
import '@/plugins/font-awesome'
import '@/plugins/vee-validate'
import '@/plugins/toast'
import '@/plugins/vue-modal'
import gtm from '@/plugins/google-tag-manager'
/* ============
 * Styling
 * ============
 */

import '@/assets/style/modules/onboarding/main.sass'

/* ============
 * Main App
 * ============
 */
import { router } from './routes'
import App from './app'

import store from './store'

import './proxies/interceptors/index'

Vue.config.productionTip = false

gtm.init().then(() => {
  new Vue({
    store,
    router,
    i18n,
    render: h => h(App) // h The render method
  }).$mount('#app')
})
