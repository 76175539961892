import Vue from 'vue'
import { ClientProxy } from '@/modules/shared/client/client-proxy'
import ExportProxy from '@/modules/shared/proxies/export-proxy'
import StatisticProxy from '@/modules/shared/proxies/statistic-proxy'
import { downloadFile } from '@/utils/download'
import * as types from './mutation-types'

import { getClientCount, setClientCount } from './counting-actions'

import {
  getLedgerDetails,
  getSummaryLedger,
  updateLedger,
  exportLedgerToExcel
} from './ledger-actions'

import {
  getPaymentAuthorization,
  getAccountingSystem,
  setCreditAccount,
  setOperatingAccount,
  setAccountingSystem,
  setAccountingSystemKey,
  getIdentities
} from './accounting-actions'

import { calculateCreditScore, updateInvoices, refetchInvoices, repay, withdraw } from './credit-actions'

import { ignoreRemarks, markEvent, updateComment } from './activity-actions'

import { addWatchlist, removeWatchlist, transitionClient, getTargetTransitionStatus } from './status-actions'

import { addTag, removeTag, getTags } from './tag-actions'

import {
  saveOverDraft,
  deleteOverDraft
} from './overdraft-actions'

const months = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER']

const onSuccess = (commit, resolve, actionType) => (result) => {
  commit(actionType, result)
  resolve(result)
}

export const fetchClient = ({ commit }, payload) => new Promise((resolve, reject) => {
  new ClientProxy().find(payload)
    .then(onSuccess(commit, resolve, types.FETCH_CLIENT))
    .catch(e => reject(e))
})

export const produceDocument = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    subject: 'Document'
  }
  new ClientProxy().produceDocument(payload, { toast })
    .then(onSuccess(commit, resolve, types.PRODUCE_DOCUMENTS))
    .catch(e => reject(e))
})

export const massCreateClient = ({ commit }, payload) => new Promise((resolve, reject) => {
  new ClientProxy().massCreateClient(payload.orgNumber, payload.clientStatus)
    .then(onSuccess(commit, resolve, types.CREATE_PROSPECT_CLIENT))
    .catch(e => reject(e))
})

export const createClient = ({ commit }, payload) => new Promise((resolve, reject) => {
  new ClientProxy().create(payload)
    .then(onSuccess(commit, resolve, types.CREATE_CLIENT))
    .catch(e => reject(e))
})

export const createClientUser = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    success: Vue.i18n.t('client.registerModal.registerSuccess')
  }
  new ClientProxy().createClientUser(payload.orgNumber, payload.user, { showLoading: true, toast })
    .then(onSuccess(commit, resolve, types.CREATE_CLIENT_USER))
    .catch(e => reject(e))
})

export const addEndUser = ({ commit }, payload) => new Promise((resolve, reject) => {
  new ClientProxy().addEndUser(payload.clientId, payload)
    .then(onSuccess(commit, resolve, types.ADD_END_USER))
    .catch(e => reject(e))
})

export const updateSettings = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    success: Vue.i18n.t('client.updateSettingSuccess')
  }
  new ClientProxy().updateSettings(payload.id, payload.value, { toast })
    .then(onSuccess(commit, resolve, types.UPDATE_CLIENT_SETTINGS))
    .catch(e => reject(e))
})

export const updateAdvisor = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    success: Vue.i18n.t('client.updateAdvisorSuccess')
  }
  new ClientProxy().updateAdvisor(payload.id, payload.value, { toast })
    .then(onSuccess(commit, resolve, types.UPDATE_ADVISOR))
    .catch(e => reject(e))
})

export const removeClientUser = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    success: Vue.i18n.t('client.removeClientUserSuccess')
  }
  new ClientProxy().removeClientUser(payload.clientId, payload.userId, { toast })
    .then(onSuccess(commit, resolve, types.UPDATE_ADVISOR))
    .catch(e => reject(e))
})

export const exportClientFilter = ({ commit, dispatch }, payload) =>
  new Promise((resolve, reject) => {
    const params = {
      ...payload
    }
    delete params.tags
    new ExportProxy(params).exportClientsFilter(payload.tags)
      .then((result) => {
        const content = result
        downloadFile('client-filter.csv', content)
        resolve(content)
      })
      .catch(e => reject(e))
  })

export const exportClientInvoice = ({ commit, dispatch }, payload) =>
  new Promise((resolve, reject) => {
    new ExportProxy(payload.params).exportInvoices(payload.clientId)
      .then((result) => {
        downloadFile('client-invoice.csv', result)
        resolve(result)
      })
      .catch(e => reject(e))
  })

export const exportClientDebtor = ({ commit, dispatch }, payload) =>
  new Promise((resolve, reject) => {
    new ExportProxy(payload.params).exportDebtor(payload.clientId)
      .then((result) => {
        downloadFile('client-debtor.csv', result)
        resolve(result)
      })
      .catch(e => reject(e))
  })

export const exportDebtorAgeSpread = ({ commit, dispatch }, payload) =>
  new Promise((resolve, reject) => {
    new ExportProxy(payload.params).exportDebtorAgeSpread(payload.clientId)
      .then((result) => {
        downloadFile('client-debtor-age-spread.csv', result)
        resolve(result)
      })
      .catch(e => reject(e))
  })

export const deleteClient = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    success: Vue.i18n.t('client.deleteClientSuccess')
  }
  new ClientProxy().deleteClient(payload.id, { toast })
    .then((result) => {
      commit(types.DELETE_CLIENT, { clientId: payload.id, status: payload.clientStatus })
      resolve(result)
    })
    .catch(e => reject(e))
})

export const deletePendingTransaction = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    success: Vue.i18n.t('client.deletePendingTransaction')
  }
  new ClientProxy().deleteTransaction(payload.id, payload.transactionId, { toast })
    .then((result) => {
      resolve(result)
    })
    .catch(e => reject(e))
})

export const updateFollowUpDate = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    success: Vue.i18n.t('client.updateFollowUpDateSuccess')
  }
  new ClientProxy().updateFollowUpDate(payload.id, payload.value, { toast })
    .then(onSuccess(commit, resolve, types.UPDATE_FOLLOW_UP_DATE))
    .catch(e => reject(e))
})

function convertMonthlyStatisticLabel (key) {
  // key format: 2020-01 -> January
  const month = parseInt(key.substr(5), 0)
  const monthIndex = month - 1
  return Vue.i18n.t(`common.app.months.${months[monthIndex]}`)
}

function getMonthlyLabel (statisticsResponse) {
  const labels = Object.keys(statisticsResponse.data).sort().map(key => convertMonthlyStatisticLabel(key))
  return [
    ...labels
  ]
}

function getDailyLabel (statisticsResponse) {
  let result = []
  const monthKey = Object.keys(statisticsResponse.data)[0]
  if (monthKey) {
    result = statisticsResponse.data[monthKey].clientHistoryList.map(item =>
      // get day
      item.date.substring(8))
  }
  return result
}

function getMonthlyDataset (statisticsResponse, monthlyLabel) {
  const tmpData = {}
  Object.keys(statisticsResponse.data).forEach((k) => {
    const month = convertMonthlyStatisticLabel(k)
    tmpData[month] = statisticsResponse.data[k]
  })

  return [
    monthlyLabel.map(k => tmpData[k].creditLimitAvg || 0),
    monthlyLabel.map(k => tmpData[k].invoiceCreditAmountAvg || 0),
    monthlyLabel.map(k => tmpData[k].drawnAmountAvg || 0),
    monthlyLabel.map(k => tmpData[k].availableCreditAvg || 0)
  ]
}

function getDailyDataset (statisticsResponse) {
  let creditLimit = []
  let invoiceCreditAmount = []
  let drawnAmount = []
  let availableCredit = []
  if (Object.keys(statisticsResponse.data).length > 0) {
    const month = Object.keys(statisticsResponse.data)[0]
    const clientHistoryList = statisticsResponse.data[month].clientHistoryList
    creditLimit = clientHistoryList.map(item => item.creditLimit || 0)
    invoiceCreditAmount = clientHistoryList.map(item => item.invoiceCreditAmount || 0)
    drawnAmount = clientHistoryList.map(item => item.drawnAmount || 0)
    availableCredit = clientHistoryList.map(item => item.availableCredit || 0)
  }
  return [
    creditLimit,
    invoiceCreditAmount,
    drawnAmount,
    availableCredit
  ]
}

export const getCreditAmountDetails = ({ commit }, payload) => new Promise((resolve, reject) => {
  new StatisticProxy({ year: payload.year, month: payload.month }).getCreditAmountDetails(payload.clientId)
    .then((result) => {
      const chartData = {
        labels: [],
        datasets: [
          {
            label: Vue.i18n.t('client.statistics.creditLimit'),
            borderColor: '#14215a',
            borderWidth: 3,
            backgroundColor: 'rgba(20,33,90,0)',
            data: []
          },
          {
            label: Vue.i18n.t('client.statistics.invoiceCreditAmount'),
            borderColor: '#009fe3',
            borderWidth: 3,
            backgroundColor: 'rgba(0,159,227,0)',
            data: []
          },
          {
            label: Vue.i18n.t('client.statistics.drawnAmount'),
            borderColor: '#dc3545',
            borderWidth: 3,
            backgroundColor: 'rgba(220,53,69,0)',
            data: []
          },
          {
            label: Vue.i18n.t('client.statistics.availableCredit'),
            borderColor: '#28a745',
            borderWidth: 3,
            backgroundColor: 'rgba(40,167,69,0)',
            data: []
          }
        ]
      }

      if (Object.keys(result.data).length === 0) {
        resolve({ data: result, chartData })
        return
      }

      const IS_MONTHLY_REPORT = !payload.month

      chartData.labels = IS_MONTHLY_REPORT ? getMonthlyLabel(result) : getDailyLabel(result)

      const chartDataset = IS_MONTHLY_REPORT ? getMonthlyDataset(result, chartData.labels) : getDailyDataset(result)
      chartData.datasets[0].data = chartDataset[0]
      chartData.datasets[1].data = chartDataset[1]
      chartData.datasets[2].data = chartDataset[2]
      chartData.datasets[3].data = chartDataset[3]
      resolve({ data: result, chartData })
    })
    .catch(e => reject(e))
})

export default {
  addWatchlist,
  removeWatchlist,
  getClientCount,
  setClientCount,
  getLedgerDetails,
  getSummaryLedger,
  getPaymentAuthorization,
  getAccountingSystem,
  fetchClient,
  repay,
  withdraw,
  setCreditAccount,
  setOperatingAccount,
  setAccountingSystem,
  setAccountingSystemKey,
  getIdentities,
  calculateCreditScore,
  produceDocument,
  updateInvoices,
  refetchInvoices,
  createClient,
  massCreateClient,
  createClientUser,
  addEndUser,
  ignoreRemarks,
  markEvent,
  updateComment,
  updateSettings,
  updateAdvisor,
  updateLedger,
  updateFollowUpDate,
  exportLedgerToExcel,
  removeClientUser,
  transitionClient,
  getTargetTransitionStatus,
  addTag,
  removeTag,
  getTags,
  exportClientFilter,
  exportClientInvoice,
  exportClientDebtor,
  exportDebtorAgeSpread,
  deleteClient,
  saveOverDraft,
  deleteOverDraft,
  getCreditAmountDetails,
  deletePendingTransaction
}
