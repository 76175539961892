import Vue from 'vue'
import { ClientProxy } from '@/modules/shared/client/client-proxy'
import * as types from './mutation-types'

const onSuccess = (commit, resolve, actionType) => (result) => {
  commit(actionType, result)
  resolve(result)
}

export const getAccountingSystem = ({ commit }, payload) => new Promise((resolve, reject) => {
  new ClientProxy().getAccountingSystem(payload)
    .then(onSuccess(commit, resolve, types.GET_ACCOUNTING_SYSTEM))
    .catch(e => reject(e))
})

export const getPaymentAuthorization = ({ commit }, payload) => new Promise((resolve, reject) => {
  new ClientProxy().getPaymentAuthorization(payload)
    .then(onSuccess(commit, resolve, types.GET_PAYMENT_AUTHORIZATION))
    .catch(e => reject(e))
})

export const setCreditAccount = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    subject: Vue.i18n.t('client.creditAccount')
  }
  new ClientProxy().setCreditAccount(payload.id, payload.value, { toast })
    .then(onSuccess(commit, resolve, types.SET_CREDIT_ACCOUNT))
    .catch(e => reject(e))
})

export const setOperatingAccount = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    subject: Vue.i18n.t('client.operatingAccount')
  }
  new ClientProxy().setOperatingAccount(payload.id, payload.value, { toast })
    .then(onSuccess(commit, resolve, types.SET_OPERATING_ACCOUNT))
    .catch(e => reject(e))
})

export const setAccountingSystem = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    subject: Vue.i18n.t('backoffice.misc.accountingSystem')
  }
  const options = payload.noToast ? null : { toast }
  return new ClientProxy().setAccountingSystem(payload.id, payload.value, options)
    .then(onSuccess(commit, resolve, types.SET_ACCOUNTING_SYSTEM))
    .catch(e => reject(e))
})

export const setAccountingSystemKey = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    subject: Vue.i18n.t('backoffice.misc.accountingSystem')
  }
  new ClientProxy().setAccountingSystemKey(payload.id, payload.value, { toast })
    .then(onSuccess(commit, resolve, types.SET_ACCOUNTING_SYSTEM_KEY))
    .catch(e => reject(e))
})

export const getIdentities = ({ commit, dispatch }, payload) => new Promise(
  (resolve, reject) => {
    payload.noToast = true
    dispatch('setAccountingSystem', payload)
      .then(() => dispatch('integration/getIdentities', payload.id))
      .then((identities) => {
        const result = identities.map(item => ({
          value: item.id,
          text: item.name
        }))
        resolve(result)
      })
      .catch(e => reject(e))
  })

export default {
  getPaymentAuthorization,
  getAccountingSystem,
  setAccountingSystem,
  setAccountingSystemKey,
  setCreditAccount,
  setOperatingAccount,
  getIdentities
}
