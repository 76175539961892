/* ============
 * State of the auth module
 * ============
 *
 * The initial state of the auth module.
 */

import { monthlyResultInLast4Years } from './ledger-actions'

export default {
  prospectCount: 0,
  onboardingCount: 0,
  customerCount: 0,
  watchlistCount: 0,
  cancelledCount: 0,
  reportingCount: 0,
  currentClient: {},
  creditScore: {},
  invoiceUpdated: null,
  paymentAuthorizationInfo: {},
  updatedComment: null,
  createdClient: {},
  removedUser: {},
  createdProspectClientsResult: {},
  possibleTransitionStatuses: {},
  summaryLedger: {},
  yearlyLedger: {
    incomeStatement: [],
    balanceStatement: []
  },
  monthlyLedger: {
    incomeStatement: monthlyResultInLast4Years(),
    balanceStatement: monthlyResultInLast4Years()
  },
  tags: []
}
