import Vue from 'vue'
import VueRouter from 'vue-router'
import VuexRouterSync from 'vuex-router-sync'
// import { getCookie, removeCookie } from 'tiny-cookie';
import routes from './routes'
import store from '../store'

Vue.use(VueRouter)

export const router = new VueRouter({
  routes
})

/*
function isExistToken() {
  return !!localStorage.getItem('onboarding_id_token') || !!getCookie('X-Authorization');
}

function setToken() {
  if (!!localStorage.getItem('onboarding_id_token') || !getCookie('X-Authorization')) {
    return;
  }

  localStorage.setItem('onboarding_id_token', getCookie('X-Authorization'));
  Vue.$http.defaults.headers.common['X-Authorization'] =
  `Bearer ${localStorage.getItem('onboarding_id_token')}`;
  removeCookie('X-Authorization');
}
*/

router.beforeEach((to, from, next) => {
  next()
/*  if (to.meta.guest) {
    next();
    return;
  }

  if (to.meta.auth) {
    setToken();
    if (isExistToken()) {
      next();
      return;
    }
    next({
      name: 'index',
    });
  } */
})

Vue.router = router
VuexRouterSync.sync(store, router)

export default {
  router
}
