import Proxy from '@/modules/shared/proxies/proxy'

/**
 * Class Integration
 */
class IntegrationProxy extends Proxy {
  /**
   * Constructor.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/integration', parameters)
  }

  /**
   *
   * @param clientId
   * @returns {Promise}
   */
  getIdentities (clientId) {
    return this.submit('get', `/${this.endpoint}/24sevenoffice/identities/${clientId}`)
  }

  /**
   *
   * @returns {Promise}
   */
  getUserOrganizations () {
    return this.submit('get', `/${this.endpoint}/infotorg/organizations`)
  }

  /**
   *
   * @returns {Promise}
   */
  getUserContexts (id) {
    return this.submit('get', `/${this.endpoint}/visma/net/usercontext/${id}`)
  }

  /**
   *
   * @returns {Promise}
   */
  setUserContext (id, userContext, options = {}) {
    return this.submit('post', `/${this.endpoint}/visma/net/usercontext/${id}`, userContext, options)
  }
}

const instance = new IntegrationProxy()
export {
  instance as default,
  IntegrationProxy
}
