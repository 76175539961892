import Proxy from '@/modules/shared/proxies/proxy'

/**
 * Class ActuatorProxy
 */
class ActuatorProxy extends Proxy {
  /**
   * Constructor.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('actuator', parameters)
  }

  getInfo () {
    return this.submit('get', `/${this.endpoint}/info`)
  }
}

export default ActuatorProxy
