/**
 * Promise based HTTP client for the browser and node.js
 */
import axios from 'axios'
import Vue from 'vue'

const instance = axios.create({
  baseURL: process.env.API_LOCATION,
  headers: {
    Accept: 'application/json'
  },
  withCredentials: true
})

Vue.prototype.$http = Vue.$http = instance

// axios.defaults.baseURL = process.env.API_LOCATION;
// axios.defaults.headers.common.Accept = 'application/json';
// axios.interceptors.request.use(config =>
//   config, error =>
//   Promise.reject(error));

// Vue.$http = axios;
// Object.defineProperty(Vue.prototype, '$http', {
//   get() {
//     return axios;
//   },
// });
