import Proxy from '@/modules/shared/proxies/proxy'

/**
 * Class ContactProxy
 */
class ContactProxy extends Proxy {
  /**
   * Constructor.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/contactinfo', parameters)
  }

  /**
   * sendContactInfo
   * @param contactInfo
   * @param options
   * @returns {Promise}
   */
  sendContactInfo (contactInfo, options = {}) {
    return this.submit('post', `/${this.endpoint}`, contactInfo, options)
  }

  /**
   * sendSupportRequest
   * @param message
   * @param options
   * @returns {Promise}
   */
  sendSupportRequest (message, options = {}) {
    return this.submit('post', `/${this.endpoint}/support`, message, options)
  }
}

export default ContactProxy
