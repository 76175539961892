/* ============
 * Mutations for the client module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */
import { CLIENT_STATUS } from '@/app-constant'

import {
  ADD_WATCHLIST,
  ADD_END_USER,
  SET_CLIENT_COUNT,
  SET_CREDIT_ACCOUNT,
  SET_OPERATING_ACCOUNT,
  SET_ACCOUNTING_SYSTEM,
  SET_POSSIBLE_TRANSITION_STATUSES,
  GET_CLIENT_YEARLY_LEDGER_DETAILS,
  GET_CLIENT_MONTHLY_LEDGER_DETAILS,
  GET_CLIENT_YEARLY_LEDGER,
  GET_CLIENT_MONTHLY_LEDGER,
  GET_CLIENT_LEDGER,
  GET_ACCOUNTING_SYSTEM,
  GET_PAYMENT_AUTHORIZATION,
  FETCH_CLIENT,
  REPAY,
  WITHDRAW,
  PRODUCE_DOCUMENTS,
  CREATE_CLIENT,
  CREATE_PROSPECT_CLIENT,
  CALCULATE_CREDIT_SCORE,
  CREATE_CLIENT_USER,
  UPDATE_INVOICES,
  IGNORE_REMARK,
  MARK_CLIENT_EVENT,
  UPDATE_COMMENT,
  UPDATE_REMARK_COMMENT,
  UPDATE_CLIENT_SETTINGS,
  UPDATE_ADVISOR,
  UPDATE_FOLLOW_UP_DATE,
  UPDATE_LEDGER,
  REMOVE_CLIENT_USER,
  DELETE_CLIENT,
  REMOVE_WATCHLIST,
  TRANSITION_CLIENT,
  GET_TAGS,
  CREATE_TAG,
  REMOVE_TAG
} from './mutation-types'

const updateClient = (state, payload) => {
  state.currentClient = payload
}

const insertLedgerDetailsIntoCurrentState = (currentLedger, groupName, ledgerDetails) => {
  let groupIndex = -1
  currentLedger.some((el, i) => {
    if (el.name === groupName) {
      groupIndex = i
      return true
    }
    return false
  })
  const NOT_FOUND_GROUP = groupIndex === -1
  if (NOT_FOUND_GROUP) {
    return currentLedger
  }
  const HAS_INSERTED = currentLedger[groupIndex + 1].group === currentLedger[groupIndex].name
  if (HAS_INSERTED) {
    return currentLedger
  }

  const INSERT_POSITION = groupIndex + 1
  const result = currentLedger.slice()
  result.splice(INSERT_POSITION, 0, ...ledgerDetails)
  return result
}

const addMonthlyLedgerDetailsForLast3Year = (ledgerStatements, groupDetails) => {
  const result = {}
  Object.keys(ledgerStatements).forEach((year) => {
    result[year.toString()] = insertLedgerDetailsIntoCurrentState(
      ledgerStatements[year],
      groupDetails.group,
      groupDetails.data[year])
  })
  return result
}

export default {
  [ADD_WATCHLIST] (state, payload) {
    state.watchlistCount += 1
    state.currentClient = payload
  },

  [REMOVE_WATCHLIST] (state, payload) {
    state.watchlistCount -= 1
    state.currentClient = payload
  },
  [SET_POSSIBLE_TRANSITION_STATUSES] (state, payload) {
    state.possibleTransitionStatuses = payload
  },
  [SET_CLIENT_COUNT] (state, payload) {
    state[`${payload.type}Count`] = payload.value
  },
  [GET_ACCOUNTING_SYSTEM] (state, payload) {
    state.accountingSystemInfo = payload
  },
  [GET_CLIENT_LEDGER] (state, payload) {
    state.summaryLedger = payload
  },
  [GET_CLIENT_YEARLY_LEDGER] (state, payload) {
    state.yearlyLedger = payload
  },

  [GET_CLIENT_MONTHLY_LEDGER] (state, payload) {
    state.monthlyLedger = payload
  },
  [GET_CLIENT_YEARLY_LEDGER_DETAILS] (state, payload) {
    const incomeStatement = state.yearlyLedger.incomeStatement
    const balanceStatement = state.yearlyLedger.balanceStatement
    state.yearlyLedger.incomeStatement = insertLedgerDetailsIntoCurrentState(
      incomeStatement,
      payload.group,
      payload.data)
    state.yearlyLedger.balanceStatement = insertLedgerDetailsIntoCurrentState(
      balanceStatement,
      payload.group,
      payload.data)
  },
  [GET_CLIENT_MONTHLY_LEDGER_DETAILS] (state, payload) {
    const incomeStatement = state.monthlyLedger.incomeStatement
    const balanceStatement = state.monthlyLedger.balanceStatement
    state.monthlyLedger.incomeStatement = addMonthlyLedgerDetailsForLast3Year(
      incomeStatement,
      payload)
    state.monthlyLedger.balanceStatement = addMonthlyLedgerDetailsForLast3Year(
      balanceStatement,
      payload)
  },
  [GET_PAYMENT_AUTHORIZATION] (state, payload) {
    state.paymentAuthorizationInfo = payload
  },
  [CREATE_CLIENT_USER] (state, payload) {
    state.createdClient = payload
  },
  [REMOVE_CLIENT_USER] (state, payload) {
    state.removedUser = payload
  },
  [FETCH_CLIENT]: updateClient,
  [REPAY]: updateClient,
  [WITHDRAW]: updateClient,
  [SET_CREDIT_ACCOUNT]: updateClient,
  [SET_OPERATING_ACCOUNT]: updateClient,
  [SET_ACCOUNTING_SYSTEM]: updateClient,
  [CREATE_CLIENT]: updateClient,
  [CREATE_PROSPECT_CLIENT]: (state, payload) => {
    state.createdProspectClientsResult = payload
  },
  [PRODUCE_DOCUMENTS]: updateClient,
  [UPDATE_CLIENT_SETTINGS]: updateClient,
  [TRANSITION_CLIENT]: (state, payload) => {
    const clientStatusCount = {
      [CLIENT_STATUS.PROSPECT]: 'prospectCount',
      [CLIENT_STATUS.ONBOARDING]: 'onboardingCount',
      [CLIENT_STATUS.IN_PRODUCTION]: 'customerCount',
      [CLIENT_STATUS.CANCELLED]: 'cancelledCount',
      [CLIENT_STATUS.REPORTING]: 'reportingCount'
    }
    state[clientStatusCount[payload.status]]++
    state[clientStatusCount[payload.fromStatus]]--
    if (state.currentClient.onWatchList && !payload.client.onWatchList) {
      state.watchlistCount--
    }
    state.currentClient = payload.client
  },
  [DELETE_CLIENT] (state, payload) {
    const clientStatusCount = {
      [CLIENT_STATUS.PROSPECT]: 'prospectCount',
      [CLIENT_STATUS.CANCELLED]: 'cancelledCount'
    }
    state[clientStatusCount[payload.status]]--
  },
  [CALCULATE_CREDIT_SCORE] (state, payload) {
    state.creditScore = payload
  },
  [UPDATE_INVOICES] (state, payload) {
    state.invoiceUpdated = payload
  },
  [UPDATE_LEDGER] (state, payload) {
    state.updatedLedger = payload
  },
  [ADD_END_USER] (state, payload) {
    state.createdEndUser = payload
  },
  [IGNORE_REMARK] (state, payload) {
    state.currentClient.sumFollowUp += !payload.ignore ? 1 : -1
  },
  [MARK_CLIENT_EVENT] (state, payload) {
    state.currentClient.sumFollowUp += payload.treated ? -1 : 1
  },
  [UPDATE_COMMENT] (state, payload) {
    state.updatedComment = payload.comment
    if (payload.clientId === state.currentClient.id) {
      state.currentClient.comment = payload.comment
    }
  },
  [UPDATE_REMARK_COMMENT] (state, payload) {
    state.updatedComment = payload
  },
  [UPDATE_ADVISOR]: updateClient,
  [UPDATE_FOLLOW_UP_DATE]: updateClient,
  [GET_TAGS] (state, payload) {
    state.tags = payload
  },
  [CREATE_TAG] (state, payload) {
    state.tags = payload
  },
  [REMOVE_TAG] (state, payload) {
    state.tags = payload
  }
}
