export const ADD_WATCHLIST = 'ADD_WATCHLIST'
export const REMOVE_WATCHLIST = 'REMOVE_WATCHLIST'
export const SET_CLIENT_COUNT = 'SET_CLIENT_COUNT'
export const GET_CLIENT_LEDGER = 'GET_CLIENT_LEDGER'
export const GET_CLIENT_YEARLY_LEDGER = 'GET_CLIENT_YEARLY_LEDGER'
export const GET_CLIENT_MONTHLY_LEDGER = 'GET_CLIENT_MONTHLY_LEDGER'
export const GET_CLIENT_YEARLY_LEDGER_DETAILS = 'GET_CLIENT_YEARLY_LEDGER_DETAILS'
export const GET_CLIENT_MONTHLY_LEDGER_DETAILS = 'GET_CLIENT_MONTHLY_LEDGER_DETAILS'
export const GET_ACCOUNTING_SYSTEM = 'GET_ACCOUNTING_SYSTEM'
export const GET_PAYMENT_AUTHORIZATION = 'GET_PAYMENT_AUTHORIZATION'
export const FETCH_CLIENT = 'FETCH_CLIENT'
export const REPAY = 'REPAY'
export const WITHDRAW = 'WITHDRAW'
export const SET_CREDIT_ACCOUNT = 'SET_CREDIT_ACCOUNT'
export const SET_OPERATING_ACCOUNT = 'SET_OPERATING_ACCOUNT'
export const SET_ACCOUNTING_SYSTEM = 'SET_ACCOUNTING_SYSTEM'
export const SET_ACCOUNTING_SYSTEM_KEY = 'SET_ACCOUNTING_SYSTEM_KEY'
export const PRODUCE_DOCUMENTS = 'PRODUCE_DOCUMENTS'
export const CALCULATE_CREDIT_SCORE = 'CALCULATE_CREDIT_SCORE'
export const UPDATE_INVOICES = 'UPDATE_INVOICES'
export const UPDATE_LEDGER = 'UPDATE_LEDGER'
export const CREATE_CLIENT = 'CREATE_CLIENT'
export const CREATE_PROSPECT_CLIENT = 'CREATE_PROSPECT_CLIENT'
export const CREATE_CLIENT_USER = 'CREATE_CLIENT_USER'
export const ADD_END_USER = 'ADD_END_USER'
export const IGNORE_REMARK = 'IGNORE_REMARK'
export const MARK_CLIENT_EVENT = 'MARK_CLIENT_EVENT'
export const UPDATE_COMMENT = 'UPDATE_COMMENT'
export const UPDATE_REMARK_COMMENT = 'UPDATE_REMARK_COMMENT'
export const UPDATE_CLIENT_SETTINGS = 'UPDATE_CLIENT_SETTINGS'
export const UPDATE_ADVISOR = 'UPDATE_ADVISOR'
export const UPDATE_FOLLOW_UP_DATE = 'UPDATE_FOLLOW_UP_DATE'
export const REMOVE_CLIENT_USER = 'REMOVE_CLIENT_USER'
export const DELETE_CLIENT = 'DELETE_CLIENT'
export const TRANSITION_CLIENT = 'TRANSITION_CLIENT'
export const SET_POSSIBLE_TRANSITION_STATUSES = 'SET_POSSIBLE_TRANSITION_STATUSES'
export const GET_TAGS = 'GET_TAGS'
export const CREATE_TAG = 'CREATE_TAG'
export const REMOVE_TAG = 'REMOVE_TAG'
export const GET_OVER_DRAFT = 'GET_OVER_DRAFT'
export const POST_OVER_DRAFT = 'POST_OVER_DRAFT'
export const UPDATE_OVER_DRAFT = 'UPDATE_OVER_DRAFT'
export const DELETE_OVER_DRAFT = 'DELETE_OVER_DRAFT'
export const GET_CREDIT_AMOUNT_DETAILS = 'GET_CREDIT_AMOUNT_DETAILS'

export default {
  REMOVE_WATCHLIST,
  ADD_WATCHLIST,
  SET_CLIENT_COUNT,
  GET_ACCOUNTING_SYSTEM,
  GET_CLIENT_LEDGER,
  GET_CLIENT_YEARLY_LEDGER_DETAILS,
  GET_CLIENT_YEARLY_LEDGER,
  GET_CLIENT_MONTHLY_LEDGER,
  GET_CLIENT_MONTHLY_LEDGER_DETAILS,
  GET_PAYMENT_AUTHORIZATION,
  FETCH_CLIENT,
  REPAY,
  WITHDRAW,
  SET_CREDIT_ACCOUNT,
  SET_OPERATING_ACCOUNT,
  SET_ACCOUNTING_SYSTEM,
  SET_ACCOUNTING_SYSTEM_KEY,
  PRODUCE_DOCUMENTS,
  CALCULATE_CREDIT_SCORE,
  UPDATE_INVOICES,
  UPDATE_LEDGER,
  CREATE_CLIENT,
  CREATE_PROSPECT_CLIENT,
  ADD_END_USER,
  IGNORE_REMARK,
  MARK_CLIENT_EVENT,
  UPDATE_COMMENT,
  UPDATE_REMARK_COMMENT,
  UPDATE_CLIENT_SETTINGS,
  UPDATE_ADVISOR,
  UPDATE_FOLLOW_UP_DATE,
  CREATE_CLIENT_USER,
  REMOVE_CLIENT_USER,
  DELETE_CLIENT,
  TRANSITION_CLIENT,
  SET_POSSIBLE_TRANSITION_STATUSES,
  GET_TAGS,
  CREATE_TAG,
  REMOVE_TAG,
  GET_OVER_DRAFT,
  POST_OVER_DRAFT,
  UPDATE_OVER_DRAFT,
  DELETE_OVER_DRAFT,
  GET_CREDIT_AMOUNT_DETAILS
}
