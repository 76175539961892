/**
 * Norwegian language file.
 */
export default {
  authenticationModal: {
    title: 'Vennligst autentiser på nytt',
    description: 'Vennligst autentiser regnskapssystemet ditt på nytt for å fortsatt kunne benytte Pareto Driftskreditt.',
    serverError: 'Autentiseringen mislyktes! Prøv igjen eller ta kontakt med Pareto for videre assistanse.'
  },
  applicationIsProcessing: 'Din søknad om finansiering er under behandling',
  sentSupportRequestMsg: 'Supportforespørselen din er sendt!',
  sentSupportRequestErrorMsg: 'Støtteforespørsel mislyktes'
}
