import Vue from 'vue'
import { ClientProxy } from '@/modules/shared/client/client-proxy'
import * as types from './mutation-types'

const onSuccess = (commit, resolve, actionType) => (result) => {
  commit(actionType, result)
  resolve(result)
}

export const addTag = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    success: Vue.i18n.t('client.tagCreated')
  }
  new ClientProxy().addTag(payload.id, payload.tagName, { toast })
    .then(onSuccess(commit, resolve, types.CREATE_TAG))
    .catch(e => reject(e))
})

export const removeTag = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    success: Vue.i18n.t('client.tagRemoved')
  }
  new ClientProxy().removeTag(payload.id, payload.tagId, { toast })
    .then(onSuccess(commit, resolve, types.REMOVE_TAG))
    .catch(e => reject(e))
})

export const getTags = ({ commit }, payload) => new Promise((resolve, reject) => {
  commit(types.GET_TAGS, [])
  new ClientProxy().getTags(payload)
    .then(onSuccess(commit, resolve, types.GET_TAGS))
    .catch(e => reject(e))
})

export default {
  addTag,
  removeTag,
  getTags
}
