<template>
  <modal :name="name"
         @before-open="beforeOpen"
         @opened="opened"
         @before-close="beforeClose"
         :height="height"
         :delay="200"
         :width="width"
         :scrollable="scrollable"
         :min-height="600"
         :click-to-close="clickToClose"
         :pivot-y="0.2">
    <div class="modal-content">
      <header class="modal-header">
        <h5 class="modal-title">{{title}}</h5>
        <button v-if="!hideCloseButton" type="button" aria-label="Close" class="close" @click="$modal.hide(name)">×</button>
      </header>
      <div class="modal-body">
        <slot v-if="params" :model="model"></slot>
      </div>
      <footer v-if="!hideFooter" class="modal-footer">
        <button type="button" class="btn" :class="okClass" @click="onOk()">{{okText}}</button>
        <button type="button" class="btn btn-secondary" @click="onCancel()">{{cancelText}}</button>
      </footer>
    </div>
  </modal>
</template>

<script>

function isVisible (elem) {
  const clientRect = elem.getClientRects && elem.getClientRects().length
  return !!(elem.offsetWidth || elem.offsetHeight || clientRect)
}

export default {
  name: 'AppModal',
  props: {
    title: {
      type: String,
      default () {
        return this.name
      }
    },
    name: {
      type: String
    },
    okText: {
      type: String,
      default () {
        return this.$t('common.app.ok')
      }
    },
    okClass: {
      type: String,
      default: 'btn-primary'
    },
    cancelText: {
      type: String,
      default () {
        return this.$t('common.app.cancel')
      }
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null
    },
    width: {
      type: [String, Number],
      default: '600px'
    },
    height: {
      type: [String, Number],
      default: 'auto'
    },
    minHeight: {
      type: [Number],
      default: 0
    },
    submitOnEnter: {
      type: Boolean,
      default: true
    },
    clickToClose: {
      type: Boolean,
      default: true
    },
    hideCloseButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      model: null,
      params: null,
      isLoaded: false,
      beforeCloseCb: null
    }
  },
  methods: {
    onEnterKeyup (event) {
      const ENTER = 13
      if (!this.submitOnEnter || event.keyCode !== ENTER ||
            !this.params || !this.$options.isVisible(this.$el)) {
        return
      }
      const params = this.model || null
      this.$emit('on-ok', params)
    },
    beforeOpen (event) {
      if (!event.params) {
        return
      }
      this.model = event.params.model
      this.params = event.params
      this.$emit('before-open', this.params)
      if (!this.hideFooter) {
        window.addEventListener('keyup', this.onEnterKeyup)
      }
    },
    opened () {
      this.$emit('opened', this)
    },
    beforeClose (event) {
      this.$emit('before-close', event)
      if (!this.hideFooter) {
        window.removeEventListener('keyup', this.onEnterKeyup)
      }
    },
    onOk () {
      this.$emit('on-ok', this.model)
    },
    onCancel () {
      this.$modal.hide(this.name, { isCancel: true })
      this.$emit('on-cancel', this.model)
    }
  },
  isVisible
}
</script>
