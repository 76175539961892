export default [
  {
    path: '/',
    name: 'index',
    component: () => import('../pages/index.page'),
    meta: {
      guest: true,
      layout: 'onboarding'
    }
  },
  {
    path: '/signup',
    name: 'index',
    component: () => import('../pages/index.page'),
    meta: {
      guest: true,
      layout: 'onboarding'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../pages/register.page'),
    meta: {
      auth: true,
      layout: 'onboarding'
    }
  },
  {
    path: '/integration',
    name: 'integration',
    component: () => import('../pages/integration.page'),
    meta: {
      auth: true,
      layout: 'onboarding'
    }
  },
  {
    path: '/register/result',
    name: 'register.result',
    component: () => import('../pages/register-result.page'),
    meta: {
      guest: true,
      layout: 'onboarding'
    }
  },
  { path: '*', redirect: '/' }
]
