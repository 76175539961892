/**
 * English language file.
 */
export default {
  authenticationModal: {
    title: 'Please reauthenticate',
    description: 'Please reauthenticate with your accounting system in order to keep using Pareto Driftskreditt.',
    serverError: 'Reauthentication failed! Please try again or contact Pareto for further assistance.'
  },
  applicationIsProcessing: 'Your application for financing is being processed',
  sentSupportRequestMsg: 'Your support request sent!',
  sentSupportRequestErrorMsg: 'Support request sending failed'
}
