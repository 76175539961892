export const getCurrentUserClients = (state) => {
  if (state.currentUser.myPageClients && state.currentUser.myPageClients.length) {
    return state.currentUser.myPageClients.map(client => ({
      value: client.id,
      text: `${client.name} (${client.orgNumber})`
    }))
  }
  return []
}

export const getAdvisorListOptions = (state) => {
  const advisorList = state.advisors.map(u => ({ value: u.id, text: u.advisorData }))
  advisorList.unshift({ value: null, text: 'Ingen' })
  return advisorList
}

export default {
  getCurrentUserClients,
  getAdvisorListOptions
}
