import Proxy from '@/modules/shared/proxies/proxy'

/**
 * Class RemarksProxy
 */
class RemarksProxy extends Proxy {
  /**
   * Constructor.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/remarks', parameters)
  }

  /**
   * @param {Object} remark
   * @returns {Promise}
   */
  ignore (id, remark) {
    return this.submit('put', `/${this.endpoint}/${id}/ignore`, remark)
  }
}

export default RemarksProxy
