import Vue from 'vue'
import { ClientProxy } from '@/modules/shared/client/client-proxy'
import * as types from './mutation-types'

const onSuccess = (commit, resolve, actionType) => (result) => {
  commit(actionType, result)
  resolve(result)
}

export const addWatchlist = ({ commit }, payload) => {
  const toast = {
    success: Vue.i18n.t('client.addWatchListSuccess'),
    error: Vue.i18n.t('client.addWatchListError')
  }

  new ClientProxy().setOnWatchList(payload.id, {
    onWatchlist: true,
    comment: payload.comment
  }, { toast })
    .then((client) => {
      commit(types.ADD_WATCHLIST, client)
    })
}

export const removeWatchlist = ({ commit }, payload) => {
  const toast = {
    success: Vue.i18n.t('client.removeWatchListSuccess'),
    error: Vue.i18n.t('client.removeWatchListError')
  }

  new ClientProxy().setOnWatchList(payload.id, {
    onWatchlist: false,
    comment: payload.comment
  },
  { toast })
    .then((client) => {
      commit(types.REMOVE_WATCHLIST, client)
    })
}

export const transitionClient = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    subject: Vue.i18n.t('client.status')
  }
  new ClientProxy().transitionClient(payload.id,
    payload.status,
    payload.subStatus,
    { showLoading: true, toast })
    .then((result) => {
      commit(types.TRANSITION_CLIENT,
        {
          fromStatus: payload.fromStatus,
          status: payload.status,
          subStatus: payload.subStatus,
          client: result
        }
      )
      resolve(result)
    })
    .catch(e => reject(e))
})

export const getTargetTransitionStatus = ({ commit }, payload) => new Promise((resolve, reject) => {
  new ClientProxy().getLegalClientTransitions(payload)
    .then(onSuccess(commit, resolve, types.SET_POSSIBLE_TRANSITION_STATUSES))
    .catch(e => reject(e))
})

export default {
  addWatchlist,
  removeWatchlist,
  getTargetTransitionStatus,
  transitionClient
}
