import Vue from 'vue'
import ToastUtils from '@/utils/toast'
import ContactProxy from '@/modules/shared/proxies/contact-proxy'
import * as types from './mutation-types'

export const checkMyCredit = ({ commit, dispatch }, payload) => new Promise((resolve, reject) => dispatch('user/updateCurrentUser', { email: payload.model.email, phone: payload.model.phone })
  .then(() => dispatch('client/createClient', payload.orgNumber))
  .then((client) => {
    resolve(client)
  })
  .catch(e => reject(e)))

export const getIdentities = ({ commit, dispatch }, payload) => new Promise(
  (resolve, reject) => {
    payload.noToast = true
    dispatch('client/setAccountingSystem', payload)
      .then(() => dispatch('integration/getIdentities', payload.id))
      .then((identities) => {
        resolve(identities)
      })
      .catch(e => reject(e))
  })

export const loadCurrentUser = ({ dispatch }) => new Promise((resolve, reject) => {
  const orgPromise = dispatch('integration/getUserOrganizations')
  const userPromise = dispatch('user/getCurrentUser')
  const toastInstance = ToastUtils.showToast('loading')
  Promise.all([orgPromise, userPromise])
    .then((values) => {
      resolve(values)
      toastInstance.goAway(0)
    })
    .catch(e => reject(e))
})

export const sendContactInfo = ({ commit, dispatch }, payload) => new Promise((resolve, reject) => {
  const toast = {
    success: Vue.i18n.t('onboarding.register.sendContactInfoSuccessMsg'),
    error: Vue.i18n.t('onboarding.register.sendContactInfoErrorMsg')
  }
  new ContactProxy().sendContactInfo(payload, { toast }).then((res) => {
    commit(types.SEND_CONTACT_INFO, res)
    resolve()
  }).catch(e => reject(e))
})

export default {
  checkMyCredit,
  getIdentities,
  loadCurrentUser,
  sendContactInfo
}
