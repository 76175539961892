import Vue from 'vue'
import { ClientProxy } from '@/modules/shared/client/client-proxy'

export const saveOverDraft = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    success: Vue.i18n.t('client.overdraft.updateSuccess'),
    error: Vue.i18n.t('client.overdraft.updateError')
  }

  new ClientProxy().saveOverDraft(payload.clientId, payload.overdraft, { toast })
    .then(result => resolve(result))
    .catch(e => reject(e))
})

export const deleteOverDraft = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    subject: Vue.i18n.t('client.overdraft')
  }

  const clientId = payload.id
  const overDraftId = payload.value
  new ClientProxy().deleteOverDraft(clientId, overDraftId, { toast })
    .then(result => resolve(result))
    .catch(e => reject(e))
})

export default {
  saveOverDraft,
  deleteOverDraft
}
