<template>
  <div id="onboarding">
    <div class="full-height-container">
      <div>
        <app-header></app-header>
        <router-view />
      </div>
      <app-footer :dark-background="footerDarkBg"></app-footer>
    </div>
    <app-dialog></app-dialog>
  </div>
</template>

<script>
import AppDialog from '@/components/app-modal/confirm-dialog'
import TranslateMixin from '@/mixins/translate'
import AppFooter from './layout/app-footer'
import AppHeader from './layout/app-header'
import BreadCrumb from './layout/breadcrumb'

export default {
  name: 'OnboardingApp',
  mixins: [TranslateMixin],
  components: {
    AppFooter,
    AppHeader,
    BreadCrumb,
    AppDialog
  },
  data () {
    return {
      footerDarkBg: true
    }
  },
  watch: {
    $route (to) {
      this.footerDarkBg = to.name !== 'index'
    }
  }
}
</script>
