import Proxy from '@/modules/shared/proxies/proxy'

/**
 * Class CommentProxy
 */
class CommentProxy extends Proxy {
  /**
   * Constructor.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/comments', parameters)
  }

  updateComment (id, parentEntity, text, options = {}) {
    // If the text is empty the it will be invalid json format
    if (!text) {
      text = {}
    }
    return this.submit('post', `/${this.endpoint}/${parentEntity}/${id}`, text, options)
  }
}

export default CommentProxy
