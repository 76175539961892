/**
 * For a multi-language application, you can
 * specify the languages you want to use here.
 */
import en from './user.en.json'
import no from './user.no.json'

export default {
  en,
  no
}
