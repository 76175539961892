import Proxy from '@/modules/shared/proxies/proxy'

/**
 * Class ExportProxy
 */
class ExportProxy extends Proxy {
  /**
   * Constructor.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/export', parameters)
  }

  /**
   * exportClientsFilter
   * @returns {Promise}
   */
  exportClientsFilter (tags = []) {
    const tagsSerialized = tags.map(t => `tags=${t.text}`).join('&')
    const tagsParams = tags.length > 0 ? `?${tagsSerialized}` : ''
    return this.submit('get', `/${this.endpoint}/clients${tagsParams}`)
  }

  /**
   * exportInvoices
   * @returns {Promise}
   */
  exportInvoices (clientId) {
    return this.submit('get', `/${this.endpoint}/${clientId}/invoice`)
  }

  /**
   * exportDebtor
   * @returns {Promise}
   */
  exportDebtor (clientId) {
    return this.submit('get', `/${this.endpoint}/${clientId}/debtor`)
  }

  /**
   * exportDebtorAgeSpread
   * @returns {Promise}
   */
  exportDebtorAgeSpread (clientId) {
    return this.submit('get', `/${this.endpoint}/${clientId}/debtor/agespread`)
  }
}

export default ExportProxy
