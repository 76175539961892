/**
 * Norwegian language file.
 */
import * as auth from './auth.json'
import * as document from './document.json'
import * as event from './event.json'
import * as misc from './misc.json'
import * as creditanalysis from './creditanalysis.json'
import * as systemevent from './systemevent.json'
import * as followup from './followup.json'
import * as setting from './setting.json'

export default {
  auth,
  document,
  event,
  misc,
  creditanalysis,
  systemevent,
  followup,
  setting,
  dashboard: 'Statistikk'
}
