export const GET_CURRENT_USER = 'GET_CURRENT_USER'
export const GET_ADVISORS = 'GET_ADVISORS'
export const UPDATE_USER = 'UPDATE_USER'
export const CREATE_USER = 'CREATE_USER'
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'
export const ENABLE_DISABLE_USER = 'ENABLE_DISABLE_USER'
export const DELETE_USER = 'DELETE_USER'
export default {
  UPDATE_USER,
  CREATE_USER,
  DELETE_USER,
  GET_CURRENT_USER,
  UPDATE_CURRENT_USER,
  ENABLE_DISABLE_USER,
  GET_ADVISORS
}
