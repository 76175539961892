/**
 * For a multi-language application, you can
 * specify the languages you want to use here.
 */
import en from './client.en.json'
import no from './client.no.json'

export default {
  en,
  no
}
