import {
  CHANGE_SELECTED_CLIENT,
  SEND_CONTACT_INFO
} from './mutation-types'

export default {
  [CHANGE_SELECTED_CLIENT] (state, clientId) {
    state.selectedClientId = clientId
  },
  [SEND_CONTACT_INFO] (state, payload) {
    state.contactInfo = payload
  }
}
