/**
 * Norwegian language file.
 */
import * as app from './app.json'
import * as constant from '../../../../resources/locale/no/constant.json'
import * as error from './error.json'

export default {
  app,
  constant,
  error
}
