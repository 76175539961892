import Vue from 'vue'
import EventProxy from '@/modules/shared/events/event-proxy'
import RemarkProxy from '@/modules/shared/proxies/remark-proxy'
import CommentProxy from '@/modules/shared/proxies/comment-proxy'
import * as types from './mutation-types'

const onSuccess = (commit, resolve, actionType) => (result) => {
  commit(actionType, result)
  resolve(result)
}

export const ignoreRemarks = ({ commit }, payload) => new Promise((resolve, reject) => {
  new RemarkProxy().ignore(payload.id, {
    comment: payload.comment,
    ignore: payload.ignore
  })
    .then(onSuccess(
      commit,
      resolve,
      !payload.isEditComment ? types.IGNORE_REMARK : types.UPDATE_REMARK_COMMENT))
    .catch(e => reject(e))
})

export const markEvent = ({ commit }, payload) => new Promise((resolve, reject) => {
  new EventProxy().markEvent(payload.id, payload.value)
    .then(onSuccess(commit, resolve, types.MARK_CLIENT_EVENT))
    .catch(e => reject(e))
})

export const updateComment = ({ commit }, payload) => new Promise((resolve, reject) => {
  const toast = {
    success: Vue.i18n.t('event.updateCommentSuccess')
  }
  new CommentProxy()
    .updateComment(payload.id, payload.parentEntity, payload.text, { toast })
    .then((result) => {
      commit(types.UPDATE_COMMENT, { clientId: payload.id, comment: result })
      resolve(result)
    })
    .catch(e => reject(e))
})

export default {
  ignoreRemarks,
  markEvent,
  updateComment
}
