import { CLIENT_STATUS } from '@/app-constant'
import { ClientProxy } from '@/modules/shared/client/client-proxy'
import * as types from './mutation-types'

export const setClientCount = ({ commit }, payload) => {
  commit(types.SET_CLIENT_COUNT, { type: payload.type, value: payload.value })
}

export const getClientCount = ({ commit }, payload) => {
  switch (payload) {
    case 'prospect':
      new ClientProxy({ size: '0', clientStatus: CLIENT_STATUS.PROSPECT }).all().then((res) => {
        commit(types.SET_CLIENT_COUNT, { type: payload, value: res.totalElements })
      })
      break
    case 'onboarding':
      new ClientProxy({ size: '0', clientStatus: CLIENT_STATUS.ONBOARDING }).all().then((res) => {
        commit(types.SET_CLIENT_COUNT, { type: payload, value: res.totalElements })
      })
      break
    case 'customer':
      new ClientProxy({ size: '0', clientStatus: CLIENT_STATUS.IN_PRODUCTION }).all().then((res) => {
        commit(types.SET_CLIENT_COUNT, { type: payload, value: res.totalElements })
      })
      break
    case 'cancelled':
      new ClientProxy({ size: '0', clientStatus: CLIENT_STATUS.CANCELLED }).all().then((res) => {
        commit(types.SET_CLIENT_COUNT, { type: payload, value: res.totalElements })
      })
      break
    case 'reporting':
      new ClientProxy({ size: '0', clientStatus: CLIENT_STATUS.REPORTING }).all().then((res) => {
        commit(types.SET_CLIENT_COUNT, { type: payload, value: res.totalElements })
      })
      break
    case 'watchlist':
      new ClientProxy({ size: '0', onWatchList: 'true' }).all().then((res) => {
        commit(types.SET_CLIENT_COUNT, { type: payload, value: res.totalElements })
      })
      break
    default:
  }
}

export default {
  getClientCount,
  setClientCount
}
