import Proxy from '@/modules/shared/proxies/proxy'

/**
 * Class EventProxy
 */
class EventProxy extends Proxy {
  /**
   * Constructor.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/events', parameters)
  }

  /**
   * Mark event as read or unread
   * @param id
   * @param isRead
   * @param options
   */
  markEvent (id, isRead, options = {}) {
    return this.submit('put', `/${this.endpoint}/${id}/treated/${isRead}`, null, options)
  }
}

export default EventProxy
